import { useState } from 'react';
import useSize from 'hooks/useSize';
import { Link } from 'react-router-dom';

// import SurveyButton from 'components/Buttons/SurveyButton';

import Logo from '../../assets/logo.svg';
import './Header.css';

const mobileSize = 850;

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const size = useSize();

  return (
    <>
      <header className={`bg-white rounded-b-2xl px-4 py-6 fixed top-0 left-0 right-0 flex items-center z-30 ${size.width < mobileSize && 'justify-between'}`}>
        <Link to="/home">
          <img src={Logo} alt="Logo New Perseption" className="block w-44 mr-28" />
        </Link>
        {
          size.width <= mobileSize ? <>
            <div className="menu-btn" onClick={() => setIsMenuOpen(true)}>
              <span className="menu-btn-burger" />
              <span className="menu-btn-burger" />
              <span className="menu-btn-burger" />
            </div>
            <nav className={`${isMenuOpen ? 'nav-menu-mobile-open' : ''} nav-menu-mobile`}>
              <div className="flex items-center space-x-6 m-10">
                <i className="fa-solid fa-xmark text-3xl cursor-pointer" onClick={() => setIsMenuOpen(false)} />
                <p className="text-xl">MENU</p>
              </div>
              <ul className="flex flex-col space-y-10 mt-20 font-semibold">
                <li>
                  <Link to="/home" className="bg-pastel-blue ml-[30px]">
                    Accueil
                  </Link>
                </li>
                <li>
                  <Link to="/study" className="bg-custom-blue ml-[60px]">
                    Notre étude
                  </Link>
                </li>
                <li>
                  <Link to="/faq" className="bg-light-pink ml-[90px]">
                    FAQ
                  </Link>
                </li>
                {/* <li>
                  <Link to="/survey" className="bg-dark-pink ml-[120px]">
                    Participer à l'étude
                  </Link>
                </li> */}
              </ul>
            </nav>
          </> : <>
           <nav>
              <ul className="flex items-center space-x-10 lg:space-x-20 font-semibold">
                <li>
                  <Link to="/home" className="duration-150 hover:text-custom-blue">Accueil</Link>
                </li>
                <li>
                  <Link to="/study" className="duration-150 hover:text-custom-blue">Notre étude</Link>
                </li>
                <li>
                  <Link to="/faq" className="duration-150 hover:text-custom-blue">FAQ</Link>
                </li>
                {/* <li>
                  <SurveyButton />
                </li> */}
              </ul>
            </nav>
          </>
        }
      </header>
    </>
  );
}

export default Header;
